import { Suitcase, HandBag, BackPack, Accessory } from "../Assets/Images/Image";
const data = [
  {
    img: Suitcase,
    name: "suitcase",
  },
  {
    img: HandBag,
    name: "handbag",
  },
  {
    img: BackPack,
    name: "backpack",
  },
  {
    img: Accessory,
    name: "accessory",
  },
];
export default data;
