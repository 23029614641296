import "./login.css";
import {
  Avatar,
  Button,
  Checkbox,
  CssBaseline,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { MdLockOutline } from "react-icons/md";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import instance from "../../../axios/axios";

const AdminLogin = () => {
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const navigate = useNavigate();
  const handleOnChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    let auth = localStorage.getItem("Authorization");
    if (auth) {
      navigate("/");
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    try {
      if (!credentials.email && !credentials.password) {
        toast.error("All fields are required", {
          autoClose: 500,
          theme: "colored",
        });
      } else if (!emailRegex.test(credentials.email)) {
        toast.error("Please enter a valid email", {
          autoClose: 500,
          theme: "colored",
        });
      } else if (credentials.password.length < 8) {
        toast.error("Please enter valid password", {
          autoClose: 500,
          theme: "colored",
        });
      } else if (credentials.email && credentials.password) {
        const sendAuth = await instance.post("/auth/login", {
          email: credentials.email,
          password: credentials.password,
        });
        const receive = await sendAuth.data;

        if (receive.status === "success") {
          toast.success("Login Successfully", {
            autoClose: 500,
            theme: "colored",
          });

          localStorage.setItem("Authorization", receive.data.token);
          localStorage.setItem("user", receive.data.user.id);
          navigate("/admin/home");
        } else {
          toast.error(receive.message, {
            autoClose: 500,
            theme: "colored",
          });
          return;
        }
      }
    } catch (error) {
      toast.error("Invalid Credentials", { autoClose: 500, theme: "colored" });
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "#1976d2" }}>
          <MdLockOutline />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            value={credentials.email}
            name="email"
            onChange={handleOnChange}
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            value={credentials.password}
            name="password"
            onChange={handleOnChange}
            label="Password"
            type={showPassword ? "text" : "password"}
            id="password"
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={handleClickShowPassword}
                  sx={{ cursor: "pointer" }}
                >
                  {showPassword ? <RiEyeFill /> : <RiEyeOffFill />}
                </InputAdornment>
              ),
            }}
          />
          {/* <TextField
            margin="normal"
            required
            fullWidth
            value={credentials.key}
            name="key"
            onChange={handleOnChange}
            label="Admin Code"
            type="password"
          /> */}
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link
                to="/forgotpassword"
                variant="body2"
                style={{ color: "#1976d2" }}
              >
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link to="/admin/register" variant="body2">
                Don't have an account?
                <span style={{ color: "#1976d2" }}> Sign Up</span>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default AdminLogin;
