import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Avatar,
  Button,
  CssBaseline,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import { MdLockOutline } from "react-icons/md";
import { toast } from "react-toastify";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import instance from "../../axios/axios";

const AddNewPassword = () => {
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  let navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!password || !confirmPassword) {
        toast.error("Please enter a password");
        return;
      }
      if (confirmPassword !== password) {
        toast.error("Password not same");
        return;
      }
      const { data } = await instance.put(`/auth/reset-password/${token}`, {
        password: password,
        passwordConfirm: confirmPassword,
      });

      if (data.status === "false") {
        if (data.stack.name === "TokenExpiredError") {
          toast.error("Token is expired Please try again", {
            autoClose: 500,
            theme: "colored",
          });
          return;
        }
        toast.error(data.message, {
          autoClose: 500,
          theme: "colored",
        });
        return;
      } else {
        toast.success(data.data, { autoClose: 500, theme: "colored" });
        navigate("/login");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg, {
        autoClose: 500,
        theme: "colored",
      });
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "#1976d2" }}>
          <MdLockOutline />
        </Avatar>
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Enter New Password"
            value={password}
            name="password"
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="password"
            type={showPassword ? "text" : "password"}
            id="password"
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={handleClickShowPassword}
                  sx={{ cursor: "pointer" }}
                >
                  {showPassword ? <RiEyeFill /> : <RiEyeOffFill />}
                </InputAdornment>
              ),
            }}
            autoFocus
          />{" "}
          <TextField
            margin="normal"
            required
            fullWidth
            label="Enter Confirm Password"
            value={confirmPassword}
            name="confirmPassword"
            onChange={(e) => setConfirmPassword(e.target.value)}
            autoComplete="password"
            type={showConfirmPassword ? "text" : "password"}
            id="confirmPassword"
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={handleClickShowConfirmPassword}
                  sx={{ cursor: "pointer" }}
                >
                  {showConfirmPassword ? <RiEyeFill /> : <RiEyeOffFill />}
                </InputAdornment>
              ),
            }}
            autoFocus
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default AddNewPassword;
