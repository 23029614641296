import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
} from "@mui/material";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Link } from "react-router-dom";

const OrderTable = ({ orders, orderDetail }) => {
  const [openOrderId, setOpenOrderId] = useState("");
  const sortedOrders = orders.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );
  console.log(orderDetail);

  return (
    <>
      <Paper
        style={{
          overflow: "auto",
          maxHeight: "500px",
        }}
      >
        <TableContainer sx={{ maxHeight: "500px" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead sx={{ position: "sticky", top: 0 }}>
              <TableRow>
                <TableCell />
                <TableCell sx={{ color: "#1976d2", fontWeight: "bold" }}>
                  User Name
                </TableCell>
                <TableCell sx={{ color: "#1976d2", fontWeight: "bold" }}>
                  Email
                </TableCell>
                <TableCell sx={{ color: "#1976d2", fontWeight: "bold" }}>
                  Paid Status
                </TableCell>
                <TableCell sx={{ color: "#1976d2", fontWeight: "bold" }}>
                  Total Amount
                </TableCell>
                <TableCell sx={{ color: "#1976d2", fontWeight: "bold" }}>
                  Order Created Date
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedOrders.map((order) => (
                <React.Fragment key={order.id}>
                  <TableRow>
                    <TableCell>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() =>
                          setOpenOrderId(
                            openOrderId === order.id ? "" : order.id
                          )
                        }
                      >
                        {<MdKeyboardArrowDown />}
                      </IconButton>
                    </TableCell>

                    <TableCell component="th" scope="row">
                      <Link to={`user/${order?.userId?.id}`}>
                        {`${order?.userId?.username}`}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link to={`user/${order?.userId?.id}`}>
                        {order?.userId?.email}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link to={`user/${order?.userId?.id}`}>
                        {order?.status}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link to={`user/${order?.userId?.id}`}>
                        {order?.totalAmount}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link to={`user/${order?.userId}`}>
                        {new Date(order?.createdAt).toLocaleDateString("en-us", {
                          weekday: "long",
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })}{" "}
                        {new Date(order?.createdAt).toLocaleTimeString("en-US")}
                      </Link>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={6}
                    >
                      <Collapse
                        in={openOrderId === order?.id}
                        timeout="auto"
                        unmountOnExit
                      >
                        <div>
                          {/* <Typography>{`Address: ${order.userId.username}`}</Typography>
                          <Typography>{`Zip Code: ${order.userId.username}`}</Typography>
                          <Typography>{`City: ${order.userId.username}`}</Typography>
                          <Typography>{`State: ${order.userId.username}`}</Typography> */}
                          <Table size="small" aria-label="purchases">
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="left"
                                  sx={{ color: "#1976d2", fontWeight: "bold" }}
                                >
                                  Product Name
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{ color: "#1976d2", fontWeight: "bold" }}
                                >
                                  Image
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{ color: "#1976d2", fontWeight: "bold" }}
                                >
                                  Price
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{ color: "#1976d2", fontWeight: "bold" }}
                                >
                                  Quantity
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{ color: "#1976d2", fontWeight: "bold" }}
                                >
                                  Rating
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {orderDetail.map((product) => (
                                <TableRow key={product.id}>
                                  <TableCell align="left">
                                    <Link
                                      to={`/admin/home/product/${product.productId?.category.categoryName}/${product.productId?.id}`}
                                    >
                                      {product.productId?.productName}
                                    </Link>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Link
                                      to={`/admin/home/product/${product.productId?.category.categoryName}/${product.productId?.id}`}
                                    >
                                      <img
                                        src={product.productId?.image}
                                        alt={product.productId?.productName}
                                        style={{
                                          width: "100px",
                                          height: "100px",
                                          objectFit: "contain",
                                        }}
                                      />
                                    </Link>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Link
                                      to={`/admin/home/product/${product.productId?.category.categoryName}/${product.productId?.id}`}
                                    >
                                      {product.productId?.price}
                                    </Link>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Link
                                      to={`/admin/home/product/${product.productId?.category.categoryName}/${product.productId?.id}`}
                                    >
                                      {product.quantity}
                                    </Link>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Link
                                      to={`/admin/home/product/${product.productId?.category.categoryName}/${product.productId?.id}`}
                                    >
                                      {isNaN(
                                        product.productId?.reviews.reduce(
                                          (total, cur) => total + cur.rate,
                                          0
                                        ) / product.productId?.reviews.length
                                      )
                                        ? "0"
                                        : product.productId?.reviews.reduce(
                                            (total, cur) => total + cur.rate,
                                            0
                                          ) / product.productId?.reviews.length}
                                    </Link>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                          
                        </div>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default OrderTable;
