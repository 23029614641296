import {
  Card,
  CardActionArea,
  CardActions,
  Rating,
  CardContent,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import styles from "./ProductCard.module.css";
import numeral from "numeral";

export default function ProductCard({ prod }) {
  return (
    <Card className={styles.main_card}>
      <CardActionArea className={styles.card_action}>
        <Box className={styles.cart_box}>
          <img
            alt={prod.productName}
            src={prod.image}
            loading="lazy"
            className={styles.cart_img}
          />
        </Box>
        <CardContent>
          <Typography gutterBottom variant="h6" sx={{ textAlign: "center" }}>
            {prod.productName.length > 20
              ? prod.productName.slice(0, 20) + "..."
              : prod.productName}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography variant="h6" color="primary">
          {numeral(prod.price).format("0,0")} VND
        </Typography>
        <Typography>
          <Rating
            precision={0.5}
            name="read-only"
            value={
              prod.reviews.reduce((tol, cur) => tol + cur.rate, 0) /
              prod.reviews.length
            }
            readOnly
          />
        </Typography>
      </CardActions>
    </Card>
  );
}
